@import "../base/preprocessor";

@keyframes openLeft {
  from {
	width: 50%;
  }


  to {
	width: 30%;
  }


}

@keyframes openLeftOffset {
  from {
	width: calc(50% + 250px);
  }


  to {
	width: calc(30% + 250px);
  }


}


@keyframes openRight {
  from {
	width: calc(50% - 400px);
  }


  to {
	width: calc(30% - 400px);
  }


}


.profile {
  position: relative;
  min-height: 100vh;

  &__logo {
	z-index: 10;
	position: relative;
	animation-delay: 1s;
	padding: 20px;
	text-align: center;

	@include media-breakpoint-up(lg) {
	  text-align: left;
	  padding-left: 5%;
	  width: 30%;
	  padding-top: 60px;
	  padding-bottom: 60px;
	}

  }


  &__image {
	text-align: center;

	@include media-breakpoint-up(lg) {
	  position: absolute;
	  bottom: 0;
	  right: 0;
	  width: 30%;
	}

	@include media-breakpoint-up(xxl) {
	  left: 50%;
	  right: auto;
	  width: 30%;
	}

	img {
	  vertical-align: top;
	  max-height: 100vh;
	}

  }


  &__body {
	background-color: darken($colorWhite, 0.2);
	padding: 20px;

	@include media-breakpoint-up(lg) {
	  padding-left: 5%;
	  background-color: transparent;
	  width: 50%;
	  position: relative;
	  z-index: 10;
	  padding-bottom: 10px;
	  animation-delay: 1.5s;
	}
	@include media-breakpoint-up(xxl) {
	  width: calc(50% - 400px);
	}
  }


  &__social {
	position: relative;
	z-index: 10;
	animation-delay: 2s;
	font-size: 24px;
	padding: 0 20px 20px;
	background-color: darken($colorWhite, 0.2);


	@include media-breakpoint-up(lg) {
	  background-color: transparent;
	  width: 50%;
	  padding-left: 5%;
	}
	@include media-breakpoint-up(xl) {
	  width: calc(50% - 400px);
	  padding-bottom: 10px;
	}

	a {
	  text-decoration: none;
	  color: inherit;
	  display: inline-block;
	  margin-right: 10px;
	}
  }


}


.curtain {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  overflow: hidden;
  display: none;

  @include media-breakpoint-up(xl) {
	display: block;
  }

  &__panel {
	top: 0;
	bottom: 0;
	position: absolute;
	background-color: darken($colorWhite, 0.2);
	animation-duration: 3s;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.000, 0.000, 0.580, 1.000);

	&--left {
	  animation-name: openLeftOffset;
	  left: 0;

	  @include media-breakpoint-up(xxl) {
		animation-name: openLeft;
	  }

	  &__triangle {
		position: absolute;
		top: 0;
		bottom: 0;
		right: -400px;
		width: 0;
		height: 0;
		border-top: 100vh solid darken($colorWhite, 0.2);
		border-right: 400px solid transparent;
	  }


	}


	&--right {
	  right: -250px;
	  animation-name: openRight;

	  @include media-breakpoint-up(xxl) {
		right: 0;
	  }

	  &__triangle {
		position: absolute;
		top: 0;
		bottom: 0;
		left: -400px;
		width: 0;
		height: 0;
		border-bottom: 100vh solid darken($colorWhite, 0.2);
		border-left: 400px solid transparent;
	  }


	}


  }


}



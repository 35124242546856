.btn-theme {
	display: inline-block;
	height: 50px;
	line-height: 46px;
  text-align: center;
  border-width: 2px;
  border-style: solid;
  font-size: 16px;
  overflow: hidden;
  transition: border-color 0.3s ease;
  max-width: 100%;
  border-color: $colorBlack;
  text-decoration: none;
  cursor: pointer;
  padding: 0;

  &:hover {
	.span-default {
	  margin-top: -50px;
	}


  }

  span {
	  padding: 0 20px;
	  height: 50px;
	  display: block;
	  transition: margin 0.3s ease;
	  color: $colorBlack;
	}


  .span-hover {
	color: $colorWhite;
	background-color: $colorBlack;
  }


}



.btn-slide {
  display: inline-block;
  height: 50px;
  line-height: 46px;
  text-align: center;
  border-width: 2px;
  border-style: solid;
  font-size: 16px;
  overflow: hidden;
  transition: border-color 0.3s ease;
  max-width: 100%;
  border-color: $colorBlack;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s, box-shadow 0.3s;

  &:focus,
  &:hover {
	color: $colorWhite;
	box-shadow: inset 6.5em 0 0 0 $colorBlack;
  }
}

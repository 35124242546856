@keyframes anim-bg-position {
  100% {
	background-position: 100vw 0px;
  }
}

@keyframes anim-scale {
  100% {
	transform: scale(1.1);
  }
}

@keyframes flyout-left {
  100% {
	opacity: 0;
	transform: scale(0.01) rotate(-180deg);
	left: -300px;
	top: -200px;
  }
}

@keyframes flyout-right {
  100% {
	opacity: 0;
	transform: scale(0.01) rotate(180deg);
	right: -300px;
	top: -200px;
  }
}

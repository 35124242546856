@import "../base/preprocessor";

.skills {
  text-align: center;
  padding: 50px 0;

  &__title {
	line-height: 1.5;
	font-size: 40px;

	@include media-breakpoint-up(md) {
	  font-size: 64px;
	}

  }

  &__list {
	position: relative;
	z-index: 20;

	@include media-breakpoint-up(lg) {
	  height: 80vh;
	}

	&__inner {
	  padding: 2.5rem 0;

	  @include media-breakpoint-up(lg) {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		padding: 10rem 0 0;
	  }

	  &__item {
		display: block;
		text-decoration: none;
		position: relative;
		font-size: 24px;
		font-weight: 700;
		line-height: 2;

		&:hover,
		&.is-active {
		  color: #000;
		}
	  }
	}
  }

  &__icons {
	position: absolute;
	top: 45%;
	left: 55%;
	width: 40%;
	max-width: 480px;
	display: none;

	@include media-breakpoint-up(lg) {
	  display: block;
	}

	&__item {
	  background-color: #fff;
	  position: absolute;
	  width: 100%;
	  height: 480px;

	  &.mask {
		z-index: 10;
	  }

	  &.backdrop {
		z-index: 5;
	  }

	  &__icon {
		font-size: 320px;
	  }
	}
  }
}

//$fa-font-path: "/assets/fonts";

$page-width: 1200px;

$colorBlack: #2e2e2e;
$colorWhite: #f1f1f1;

$grid-breakpoints: (
		xs: 0,
		sm: 576px,
		md: 768px,
		lg: 992px,
		xl: 1200px,
		xxl: 1600px
);

$fontSerif: 'Aleo', Rockwell, Courier Bold, Courier, Georgia, Times, Times New Roman, serif;
$fontSansSerif: 'DroidSans', Arial, Helvetica, sans-serif;

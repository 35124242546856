@function calc-percent($target) {
  @return ($target / 978) * 100%;
}


@mixin shadow-bottom() {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.05);
}


@mixin clearfix() {
  &:after {
	content: "";
	display: table;
	clear: both;
  }


}


@mixin text-animation() {
  & {
	/*
background: linear-gradient(
  60deg,
  hsl(0, 75%, 50%) 10%,
  hsl(20, 75%, 50%) 10%,
  hsl(20, 75%, 50%) 25%,
  hsl(50, 75%, 50%) 25%,
  hsl(50, 75%, 50%) 40%,
  hsl(130, 75%, 50%) 40%,
  hsl(130, 75%, 50%) 55%,
  hsl(200, 75%, 50%) 55%,
  hsl(200, 75%, 50%) 70%,
  hsl(260, 75%, 50%) 70%,
  hsl(260, 75%, 50%) 85%,
  hsl(0, 75%, 50%) 85%
);
*/
	background: linear-gradient(60deg,
			#91a4b1,
			#f1f1f1,
			#91a4b1,
	);

	color: #fff;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: 10s anim-bg-position linear infinite;
  }


}



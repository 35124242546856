$matrix-font-size: 32px;
$matrix-line-height: 48px;
$matrix-color: lighten(green, 50%);
$matrix-background: #000;
$matrix-width: 40;
$matrix-height: 48;
$matrix-characters: ラ ド ク リ フ マ ラ ソ ン わ た し ワ タ シ ん ょ ン ョ た
  ば こ タ バ コ と う き ょ う ト ウ キ ョ ウ;

$frames-count: $matrix-height;

@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}
	@-moz-keyframes #{$name} {
		@content;
	}
	@-o-keyframes #{$name} {
		@content;
	}
	@keyframes #{$name} {
		@content;
	}
}

@function rand($min: 1, $max: 100) {
	@return random($max - $min) + $min;
}

@function random-character() {
	$length: length($matrix-characters);
	@return nth($matrix-characters, rand($max: $length));
}

@function new-column($height) {
	$result: ();
	$length: length($matrix-characters);
	@for $i from 1 through $height {
	  $result: append($result, random-character());
	}
	@return $result;
}

@function create-columns($number) {
	$columns: ();
	@for $i from 1 through $height {
	  $columns: append($columns, new-column($matrix-height));
	}
	@return $columns;
}

.matrix {
	display: block;
	color: $matrix-color;
	font-size: $matrix-font-size;
	line-height: $matrix-line-height;
	overflow: hidden;
	white-space: nowrap;
	background-color: $matrix-background;

	&__code {
	  position: relative;
	  display: inline-block;
	  white-space: normal;
	  vertical-align: top;

	  &:before {
		display: block;
		width: 1em;
	  }

	  &:after {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		color: white;
		overflow: hidden;
	  }
	}

	@for $i from 1 through $matrix-width {
		$column-height: rand(4, $matrix-height);
		$column-position: rand(1, $matrix-height);
		$column: new-column($matrix-height);

		&__code:nth-child(#{$i}) {
			&:before {
			  content: "#{$column}";
			}

			&:after {
			  background: linear-gradient(
							  rgba($matrix-background, 1) 0,
							  rgba($matrix-background, 0) ($matrix-line-height * ($column-height - 1)),
							  rgba($matrix-background, 1) ($matrix-line-height * ($column-height - 1)),
							  rgba($matrix-background, 1) ($matrix-line-height * $matrix-height)
			  ) 0 ($matrix-line-height * ($column-position - $column-height)) repeat;
			  content: "#{nth($column, $column-position)}";
			  padding-top: ($column-position - 1) * $matrix-line-height;

			  animation-delay: 0s;
			  animation-direction: normal;
			  animation-duration: 3.84s;
			  animation-fill-mode: none;
			  animation-iteration-count: infinite;
			  animation-name: column-#{$i};
			  animation-play-state: paused;
			  animation-timing-function: step-start;
			}
		}

		@keyframes column-#{$i} {
			@for $frame from 1 through $frames-count {
			  @if $column-position > $matrix-height {
				$column-position: 1;
			  }
			  #{100% * ($frame - 1) / $frames-count} {
				background-position: 0 ($matrix-line-height * ($column-position - $column-height));
				padding-top: ($column-position - 1) * $matrix-line-height;
			  }
			  $column-position: $column-position + 1;
			}
		}
	}
}

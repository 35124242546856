@import "../base/preprocessor";
.contact {
	text-align: center;
	padding: 50px 0;

	&__title {
	  font-size: 64px;
	  line-height: 1.5;

	  @include media-breakpoint-up(md) {
		font-size: 40px;
	  }
	}

	&__form {
		margin: 0 auto 20px;
		max-width: 540px;
		padding: 0 20px;

		&__messages {
		  padding: 10px;
		  font-weight: 700;
		  margin-bottom: 10px;

		  &.status-0 {
			background-color: #fef5f1;
			border: 1px solid #ed541d;
			color: #8c2e0b;
		  }

		  &.status-1 {
			background-color: #f8fff0;
			border: 1px solid #be7;
			color: #234600;
		  }

		  p {
			margin: 0;
		  }
		}

		.form-item {
			margin-bottom: 20px;

			.form-input {
			  input {
				width: 100%;
			  }

			  textarea {
				width: 100%;
				height: 250px;
			  }
			}

		  .g-recaptcha {
			> div {
			  margin: 0 auto;
			}
		  }
		}

	  .form-actions {
		.form-submit {
		  @extend .btn-slide;

		  width: 100px;
		}
	  }
	}

  &__info {
	a {
	  font-weight: 700;
	  color: $colorBlack;
	}
  }

  &__social {
	font-size: 24px;

	a {
	  font-weight: 400;
	  text-decoration: none;
	  color: $colorBlack;
	  display: inline-block;
	  margin: 0 10px;
	}
  }
}

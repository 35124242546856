@keyframes mask {
  from {
	mask-position: 100% 0;
  }
  to {
	mask-position: 0 0;
  }
}

.mask {
  mask-position: 0 0;
  animation-name: mask;
  animation-duration: 0.5s;
  animation-timing-function: steps(22);
  mask-size: 2300% 100%;
  mask-image: url("../../static/images/mask.png");
}

@font-face {
  font-family: 'DroidSans';
  src: url('../../static/fonts/DroidSans-webfont.eot');
  src: url('../../static/fonts/DroidSans-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../static/fonts/DroidSans-webfont.woff') format('woff'),
  url('../../static/fonts/DroidSans-webfont.ttf') format('truetype'),
  url('../../static/fonts/DroidSans-webfont.svg#DroidSans') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DroidSans';
  src: url('../../static/fonts/DroidSans-Bold-webfont.eot');
  src: url('../../static/fonts/DroidSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../static/fonts/DroidSans-Bold-webfont.woff') format('woff'),
  url('../../static/fonts/DroidSans-Bold-webfont.ttf') format('truetype'),
  url('../../static/fonts/DroidSans-Bold-webfont.svg#DroidSans') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Aleo';
  src: url('../../static/fonts/Aleo-Light-webfont.eot');
  src: url('../../static/fonts/Aleo-Light-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../static/fonts/Aleo-Light-webfont.woff') format('woff'),
  url('../../static/fonts/Aleo-Light-webfont.ttf') format('truetype'),
  url('../../static/fonts/Aleo-Light-webfont.svg#aleolight') format('svg');
  font-weight: 300;
  font-style: normal;

}

@font-face {
  font-family: 'Aleo';
  src: url('../../static/fonts/Aleo-LightItalic-webfont.eot');
  src: url('../../static/fonts/Aleo-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../static/fonts/Aleo-LightItalic-webfont.woff') format('woff'),
  url('../../static/fonts/Aleo-LightItalic-webfont.ttf') format('truetype'),
  url('../../static/fonts/Aleo-LightItalic-webfont.svg#aleolightitalic') format('svg');
  font-weight: 300;
  font-style: italic;

}

@font-face {
  font-family: 'Aleo';
  src: url('../../static/fonts/Aleo-Regular-webfont.eot');
  src: url('../../static/fonts/Aleo-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../static/fonts/Aleo-Regular-webfont.woff') format('woff'),
  url('../../static/fonts/Aleo-Regular-webfont.ttf') format('truetype'),
  url('../../static/fonts/Aleo-Regular-webfont.svg#aleoregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Aleo';
  src: url('../../static/fonts/Aleo-Italic-webfont.eot');
  src: url('../../static/fonts/Aleo-Italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../static/fonts/Aleo-Italic-webfont.woff') format('woff'),
  url('../../static/fonts/Aleo-Italic-webfont.ttf') format('truetype'),
  url('../../static/fonts/Aleo-Italic-webfont.svg#aleoitalic') format('svg');
  font-weight: normal;
  font-style: italic;

}

@font-face {
  font-family: 'Aleo';
  src: url('../../static/fonts/Aleo-Bold-webfont.eot');
  src: url('../../static/fonts/Aleo-Bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../static/fonts/Aleo-Bold-webfont.woff') format('woff'),
  url('../../static/fonts/Aleo-Bold-webfont.ttf') format('truetype'),
  url('../../static/fonts/Aleo-Bold-webfont.svg#aleobold') format('svg');
  font-weight: 700;
  font-style: normal;

}

@font-face {
  font-family: 'Aleo';
  src: url('../../static/fonts/Aleo-BoldItalic-webfont.eot');
  src: url('../../static/fonts/Aleo-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../static/fonts/Aleo-BoldItalic-webfont.woff') format('woff'),
  url('../../static/fonts/Aleo-BoldItalic-webfont.ttf') format('truetype'),
  url('../../static/fonts/Aleo-BoldItalic-webfont.svg#aleobolditalic') format('svg');
  font-weight: 700;
  font-style: italic;

}


.animated-background {
	background-color: $colorBlack;
  perspective: 700px;
  height: 100%;

  &__grid {
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 75s;
	animation-fill-mode: none;
	animation-iteration-count: infinite;
	animation-name: animatedBackground;
	animation-play-state: paused;
	animation-timing-function: linear;

	display: flex;
	flex-flow: row wrap;
	flex-basis: auto;
	flex-grow: 1;
	justify-content: flex-start;
	align-content: flex-start;
	min-width: 3080px;
  }
}

@keyframes animatedBackground {
  0% {
	transform: scale(1.25) rotateX(20deg) translateX(-600px) translateY(-400px);
  }
  100% {
	transform: scale(1.25) rotateX(25deg) translateX(-600px) translateY(-2800px);
  }
}

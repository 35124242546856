@import "preprocessor";

* {
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}


html,
body {
  font-family: $fontSansSerif;
  color: $colorBlack;
  line-height: 1.5;
}


img {
  max-width: 100%;
}


h1,
.h1 {
  font-family: $fontSerif;
  margin-bottom: 10px;
  font-size: 1.5em;

  @include media-breakpoint-up(md) {
	font-size: 1.7em;
  }


}


h2,
.h2 {
  font-size: 1.2em;
  font-family: $fontSerif;
  margin-bottom: 10px;
  font-weight: 700;

  @include media-breakpoint-up(md) {
	font-size: 1.5em;
  }


}


h3,
.h3 {
  font-size: 1.2em;
  font-weight: 700;
  font-family: $fontSerif;
  margin-bottom: 10px;
}


p {
  margin-bottom: 10px;
}


strong {
  font-weight: 700;
}


code {
  background-color: #ddd;
  border-radius: 3px;
  padding: 2px 4px;
  font-family: Courier New, Courier, monospace;
  border: 1px solid #ccc;
}



main {

  ol {
	margin: 10px 0;
	padding-left: 20px;
	list-style-type: decimal;

	li {
	  margin: 5px 0;
	}
  }

  ul {
	margin: 10px 0;
	padding-left: 20px;
	list-style-type: circle;

	li {
	  margin: 5px 0;
	}
  }

}

.right-text {
  text-align: right;
}


.clear {
  @include clearfix();
}


.full-width-box {
  max-width: $page-width + 40;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;

  @include media-breakpoint-up(md) {
	padding-left: 10px;
	padding-right: 10px;
  }


}


.animated {

  &.standby {
	-webkit-animation: none !important;
	-o-animation: none !important;
	animation: none !important;
	visibility: hidden;
  }


  .is-exiting & {
	animation-direction: reverse;
  }


}


.page-section {
  box-shadow: inset 0 300px 250px -250px $colorWhite;
  position: relative;

  @include media-breakpoint-up(lg) {
	min-height: 100vh;
  }
}

.cursor-pointer {
  cursor: pointer;
}


@import "../base/preprocessor";

.gallery-grid {
  max-width: $page-width;
  margin: 0 auto;
  position: relative;
  text-align: left;
  z-index: 5;
  padding: 50px 20px;

  &__back {
	margin-bottom: 20px;

	@include media-breakpoint-up(md) {
	  display: block;
	}
  }

  &__container {
	@include clearfix();

	text-align: center;
  }

  &__chunk {
	width: auto;
	margin: 0;

	@include media-breakpoint-up(md) {
	  float: left;
	  width: 49%;
	}
	@include media-breakpoint-up(lg) {
	  margin-right: 2%;
	  width: 32%;
	}

	&.col-2-odd {
	  @include media-breakpoint-up(md) {
		float: left;
	  }
	}

	&.col-2-even {
	  @include media-breakpoint-up(md) {
		float: right;
	  }
	  @include media-breakpoint-up(lg) {
		float: left;
	  }
	}

	&.col-3-third {
	  @include media-breakpoint-up(lg) {
		margin-right: 0;
	  }
	}

	&__item {
	  @include clearfix();

	  margin-bottom: 20px;

	  &.item-1 {
		.col-2-odd & {
		  float: left;
		  width: 48%;
		  height: 100px;
		}

		.col-2-even & {
		  height: 200px;
		}
	  }

	  &.item-2 {
		.col-2-odd & {
		  float: right;
		  width: 48%;
		  height: 100px;
		}

		.col-2-even & {
		  float: left;
		  width: 48%;
		  height: 100px;
		}
	  }

	  &.item-3 {
		.col-2-odd & {
		  clear: both;
		  height: 200px;
		}

		.col-2-even & {
		  float: right;
		  width: 48%;
		  height: 100px;
		}
	  }

	  .card {
		display: block;

		.face {
		  background-color: #f1f1f1;

		  &.front img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: 50% 50%;
		  }
		}

		.gallery-item-content {
		  transform: translateY(-50%);
		  position: absolute;
		  width: 100%;
		  top: 50%;
		  padding: 10px;
		  color: $colorBlack;
		}
	  }
	}
  }
}


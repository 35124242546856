@import "../base/preprocessor";

.portfolio {
  text-align: center;
  position: relative;

  &__parallax {
	position: relative;
	z-index: 5;

	@include media-breakpoint-up(lg) {
	  min-height: 100vh;
	}

	&__layer {
	  background-color: #000;
	  background-repeat: no-repeat;
	  background-size: cover;
	  background-position: center;
	  cursor: pointer;
	  position: relative;
	  height: 300px;

	  @include media-breakpoint-up(lg) {
		position: absolute;
		width: 50%;
		top: 0;
		bottom: 0;
		height: auto;
	  }

	  &--programming {
		background-image: url("../../static/images/vancouver-web-development.jpg");

		@include media-breakpoint-up(lg) {
		  left: 0;
		}

		&:hover {
		  .matrix {
			@include media-breakpoint-up(lg) {
			  opacity: 1;
			}

			&__code {
			  &:after {
				@include media-breakpoint-up(lg) {
				  animation-play-state: running;
				}
			  }
			}
		  }
		}

		.matrix {
		  opacity: 0;
		  transition: opacity 0.3s;
		  position: absolute;
		  top: 0;
		  left: 0;
		  right: 0;
		  bottom: 0;
		}
	  }

	  &--graphics {
		overflow: hidden;
		background-image: url("../../static/images/darkroad.jpg");

		@include media-breakpoint-up(lg) {
		  right: 0;
		}

		&:hover {
		  .animated-background {
			@include media-breakpoint-up(lg) {
			  opacity: 1;
			}

			&__grid {
			  @include media-breakpoint-up(lg) {
				animation-play-state: running;
			  }
			}
		  }
		}

		.animated-background {
		  opacity: 0;
		  transition: opacity 0.3s;
		  display: none;

		  @include media-breakpoint-up(lg) {
			display: block;
		  }

		  &__grid {
			&__item {
			  width: 308px;
			  background-size: cover;
			  background-position: center;
			  background-repeat: no-repeat;

			  &:after {
				content: "";
				padding-top: 56%;
				display: block;
			  }
			}
		  }
		}
	  }


	  &__title {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 5;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba($colorBlack, 0.9);
		color: #fff;
		margin: 0;
		font-size: 48px;
		padding: 0 30px;

		@include media-breakpoint-up(lg) {
		  font-size: 64px;
		}
	  }
	}
  }
}



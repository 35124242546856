.card-wrapper {
	perspective: 800px;

	height: 260px;
	position: relative;
	border: 1px solid #CCC;
}
.card {
	transition: transform 1s;
	transform-style: preserve-3d;
	transform-origin: right center;
	width: 100%;
	height: 100%;
	position: absolute;
}
.card.flipped,
.card-wrapper:hover .card,
.card-wrapper:active .card {
	transform: translateX( -100% ) rotateY( -180deg );

}
.card .face {
	backface-visibility: hidden;
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
}
.card .face.front {
}
.card .face.back {
	transform: rotateY( 180deg );
}

@import "../base/preprocessor";

.programming-grid {
  @include clearfix();

  max-width: $page-width;
  margin: 0 auto;
  position: relative;
  z-index: 5;
  text-align: left;
  padding: 50px 20px;

  &__back {
	margin-bottom: 20px;
	width: 100%;
	display: block;

	@include media-breakpoint-up(md) {
	  width: auto;
	}
  }

  &__grid {
	@include clearfix();

	text-align: center;

	&__item {
	  @include shadow-bottom;

	  position: relative;
	  background-color: $colorWhite;
	  text-align: center;
	  margin-bottom: 20px;

	  &:nth-child(4n) {
		margin-right: 0;
	  }

	  @include media-breakpoint-up(md) {
		margin-right: 2%;
		float: left;
		width: 23.5%;
	  }

	  &__figure {
		position: relative;
		display: flex;
		text-align: center;
		overflow: hidden;
		height: 200px;
		color: $colorWhite;
		justify-content: center;
		align-items: center;

		&:after {
		  content: '';
		  background: $colorBlack;
		  width: 100%;
		  height: 100%;
		  position: absolute;
		  left: 0;
		  bottom: 0;
		  opacity: 0.7;
		  transform: skew(-45deg) scaleX(0);
		  transition: all 0.3s ease-in-out;
		}

		&:hover {
		  &:after {
			transform: skew(-45deg) scaleX(1);
			transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
		  }

		  .content {
			transform: translate3d(0%, 0%, 0);
			transition-delay: 0.2s;
			opacity: 1;
		  }
		}

		&__image {
		  max-width: 90%;
		}

		&__caption {
		  position: absolute;
		  top: 50%;
		  left: 0;
		  width: 100%;
		  transform: translateY(-50%);
		  z-index: 1;

		  .content {
			opacity: 0;
			transition: opacity 0.2s;
			padding: 0 40px;
		  }
		}
	  }
	}
  }

}

.single--programming {
  background-image: linear-gradient(90deg, #fff 50%, $colorWhite 50%);

  @include media-breakpoint-up(md) {
	background: none;
  }

  &__top {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
			'logo description'
			'tags description'
			'link description';
	grid-row-gap: 10px;
	grid-column-gap: 10%;
	max-width: $page-width;
	margin: 0 auto;
	padding: 50px 20px;

	@include media-breakpoint-up(md) {
	  display: block;
	}

	&__logo {
	  grid-area: logo;

	  img {
		max-height: 200px;
	  }
	}

	&__description {
	  grid-area: description;
	}

	&__tags {
	  grid-area: tags;
	}

	&__link {
	  grid-area: link;
	}
  }

	&__case-studies {
		max-width: $page-width;
		margin: 0 auto;
		padding: 0 20px;

		&__sticky {
		  width: 40%;
		  float: left;
		  position: sticky;
		  top: calc(50% - 180px);

		  @include media-breakpoint-up(md) {
			display: none;
		  }

		  &__background {
			background-image: url('../../static/images/molumen_LCD_Monitor.svg');
			background-repeat: no-repeat;
			background-size: contain;
			position: relative;
			padding-top: 313 / 382 * 100%;

			&__slider {
			  position: absolute;
			  top: 17 / 313 * 100%;
			  left: 18 / 382 * 100%;
			  right: 18 / 382 * 100%;
			  bottom: 80 / 313 * 100%;

			  &__panel {
				text-align: center;
				height: 100%;
				overflow: hidden;

				&.has-image- {
				  background-color: $colorWhite;

				  img {
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				  }
				}
			  }
			}
		  }
		}

	  &__item {
		margin-left: 50%;
		padding: 50px;
		min-height: 50vh;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include media-breakpoint-up(md) {
		  display: block;
		  min-height: 0;
		  padding: 0;
		  margin: 0 0 30px;
		}
	  }
	}
}

.programmings-wheel {
  position: relative;
  padding: 30px 0;
  overflow: hidden;

  .programmings-wheel-title {
	text-align: center;
  }

  .programmings-mobile-list {
	display: none;

	@include media-breakpoint-up(md) {
	  display: block;
	}

	&__item {
	  margin-bottom: 10px;
	  font-weight: 700;
	  text-align: center;

		&.active {
		  a {
			border: 2px solid $colorBlack;
		  }
		}

	  a {
		color: $colorBlack;
		display: inline-block;
		padding: 5px 10px;
		text-decoration: none;
	  }
	}
  }

  .programmings-slider {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 400px;

	@include media-breakpoint-up(md) {
	  display: none;
	}

	  &__panel {
		text-align: center;
	  }
	}

	.nav-wheel {
	  &__list {
		border-color: $colorBlack;
	  }
	}

	.circle-menu {
	  @include media-breakpoint-up(md) {
		display: none;
	  }

	  &__circle {
		width: 700px;
		height: 700px;

		@include media-breakpoint-up(lg) {
		  width: 450px;
		  height: 450px;
		}

		&__svg {
		  &__circle {
			stroke: $colorBlack;
		  }
		}

		&__item {
		  &.active {
			.programming-title {
			  a {
				background-color: $colorBlack;
				color: $colorWhite;
			  }
			}
		  }

				&__inner {
				  width: 15px;
				  height: 15px;

				  .programming-title {
					display: block;
					position: absolute;
					text-align: center;
					width: 150px;

					@include media-breakpoint-up(lg) {
					  width: 130px;
					}

					a {
						background-color: $colorWhite;
						display: block;
						padding: 5px 10px;
						text-decoration: none;
						color: $colorBlack;
						font-weight: 700;

						@include media-breakpoint-up(lg) {
						  font-size: 14px;
						}

						&:hover {
						  background-color: $colorBlack;
						  color: $colorWhite;
						}
					}
				  }
				}
		}
	  }
	}

  .circle-menu__circle__item.position--top .programming-title {
	left: -75px;
	bottom: 20px;
	text-align: center;

	@include media-breakpoint-up(lg) {
	  left: -65px;
	}
  }

  .circle-menu__circle__item.position--top-right .programming-title {
	left: 20px;
	bottom: 20px;
	text-align: left;
  }

  .circle-menu__circle__item.position--right .programming-title {
	left: 20px;
	top: 50%;
	transform: translateY(-50%);
	text-align: left;
  }

  .circle-menu__circle__item.position--bottom-right .programming-title {
	left: 20px;
	top: 20px;
	text-align: left;
  }

  .circle-menu__circle__item.position--bottom .programming-title {
	left: -75px;
	top: 20px;
	text-align: center;

	@include media-breakpoint-up(lg) {
	  left: -65px;
	}
  }

  .circle-menu__circle__item.position--bottom-left .programming-title {
	right: 20px;
	top: 20px;
	text-align: right;
  }

  .circle-menu__circle__item.position--left .programming-title {
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	text-align: right;
  }

  .circle-menu__circle__item.position--top-left .programming-title {
	right: 20px;
	bottom: 20px;
	text-align: right;
  }
}
